import React, { useEffect, useState } from "react";
import Logo from "../assets/Logo.png";
import StarIcon from "../assets/StartIcon.png";
import moment from "moment";
type Props = {};
const hours = new Date().getHours();
const Page1 = ({ AQIInfo, NoiseData }: any) => {
  const [NoiseInfo, setNoiseInfo] = useState<any>({ laeqt: null });
  const [time, setTime] = useState<string>("");
  const [isDayTime, setIsDayTime] = useState(hours > 6 && hours < 20);

  useEffect(() => {
    // Update the time every second
    const intervalId = setInterval(() => {
      setIsDayTime(hours > 6 && hours < 20);
      setTime(moment().format("hh:mm A"));
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const limit = isDayTime ? 65 : 55;

    console.log(NoiseInfo, isDayTime);

    if (limit < NoiseData?.laeqt) {
      setNoiseInfo({ laeqt: NoiseData?.laeqt, color: "#FF0000" });
    } else {
      setNoiseInfo({ laeqt: NoiseData?.laeqt, color: "#00af50" });
    }
  }, [NoiseData]);

  return (
    <div className="flex justify-center items-center w-screen h-screen  bg-[#181818] ">
      <div className="h-[320px] w-[640px] bg-[#181818] scale-y-[1.4]">
        {/* Header */}
        <div className="flex items-center justify-center">
          <img src={Logo} className="w-22 h-20" />
          <div className="mt-4">
            <h1 className=" text-[#10A8FF] font-semibold text-[1.55rem]">
              WEST BENGAL POLLUTION CONTROL BOARD
            </h1>
            <h1 className=" text-[#fff] text-center font-semibold text-[1.7rem]">
              Paribesh Bhawan - HQ
            </h1>
          </div>
        </div>
        {/* Main content */}
        <div className="px-10 flex justify-between mt-4">
          <h1 className="text-white text-2xl font-medium ">
            TEMP{" "}
            <span className="text-[#DBFF00]">
              {Number(AQIInfo[0]?.current_ext_temp).toFixed(2)}°C
            </span>
          </h1>
          <h1 className="text-white text-2xl font-medium ">
            RH{" "}
            <span className="text-[#DBFF00]">
              {Number(AQIInfo[0]?.current_ext_humi).toFixed(2)}%
            </span>
          </h1>
          <h1 className="text-white text-2xl font-medium ">{time}</h1>
        </div>
        {/* Noice */}
        <div className="flex items-center justify-center mt-6">
          <h1 className="text-white text-4xl font-bold pr-2">NOISE </h1>
          <h1
            style={{ backgroundColor: NoiseInfo?.color }}
            className="text-black text-4xl font-bold rounded  px-2"
          >
            {Number(NoiseInfo?.laeqt).toFixed(2)} dBA
          </h1>
        </div>
        {/* details */}
        <div className="px-14 flex justify-between mt-4">
          <h1 className="text-white text-xl font-bold ">Commercial Zone</h1>
          <h1 className="text-white text-xl font-bold ">
            Limit: <span className="text-[#61FF16]">{isDayTime ? 65 : 55}</span>{" "}
            dB
          </h1>
        </div>
        {/* diviver */}
        <div className="w-full h-[0.5px] bg-[#D9D9D9] my-3" />

        {/* disclamer */}
        <div className="overflow-x-hidden">
          <div className=" animate-marquee whitespace-nowrap flex gap-2 items-center justify-center">
            <span className=" text-white text-xl font-semibold flex">
              HIGHER NOISE LEVELS CAN HAVE HARMFUL EFFECT ON HEALTH{" "}
            </span>
            <img src={StarIcon} className="w-5 h-5" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page1;
