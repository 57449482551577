import React, { useEffect, useState } from "react";
import Logo from "../assets/Logo.png";
import StarIcon from "../assets/StartIcon.png";
import moment from "moment";
type Props = {};

const Appx = ({ AQIInfo, NoiseInfo, AQIColor }: any) => {
  const [time, setTime] = useState<string>("");

  useEffect(() => {
    // Update the time every second
    const intervalId = setInterval(() => {
      setTime(moment().format("hh:mm A"));
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="flex justify-center items-center w-screen h-screen bg-[#181818]">
      <div className="h-[320px] w-[640px] bg-[#181818]  scale-y-[1.4]">
        {/* Header */}
        <div className="flex items-center justify-center">
          <img src={Logo} className="w-22 h-20" />
          <div className="mt-4">
            <h1 className=" text-[#10A8FF] font-semibold text-[1.55rem]">
              WEST BENGAL POLLUTION CONTROL BOARD
            </h1>
            <h1 className=" text-[#fff] text-center font-semibold text-[1.7rem]">
              Paribesh Bhawan - HQ
            </h1>
          </div>
        </div>
        {/* Main content */}
        <div className="px-10 flex justify-between mt-2">
          <h1 className="text-white text-2xl font-medium ">
            TEMP{" "}
            <span className="text-[#DBFF00]">
              {Number(AQIInfo[0]?.current_ext_temp).toFixed(2)}°C
            </span>
          </h1>
          <h1 className="text-white text-2xl font-medium ">
            RH{" "}
            <span className="text-[#DBFF00]">
              {Number(AQIInfo[0]?.current_ext_humi).toFixed(2)}%
            </span>
          </h1>
          <h1 className="text-white text-2xl font-medium ">{time}</h1>
        </div>
        {/* Table */}
        <div className="px-14">
          {/* Heading */}
          <div className="overflow-hidden rounded mt-2">
            <h1
              style={{ backgroundColor: AQIColor.color }}
              className={`  text-black text-2xl font-bold text-center py-1`}
            >
              AQI {Number(AQIInfo[0]?.aqi).toFixed(0)} {AQIColor.type}
            </h1>

            {/* Rows */}
            <div className=" grid grid-cols-4">
              <h1 className="text-xl text-white text-center py-[2px] font-semibold border-white border-b-[1px] border-l-[1px] border-r-[1px]">
                PM10
              </h1>
              <h1 className="text-xl text-[#52FF00] text-center py-[2px] font-semibold border-white border-b-[1px]  border-r-[1px]">
                {Number(AQIInfo[0]?.pm10_avg || 0).toFixed(2)} ug/m³
              </h1>
              <h1 className="text-xl text-white text-center py-[2px] font-semibold border-white border-b-[1px]  border-r-[1px]">
                SO2
              </h1>
              <h1 className="text-xl text-[#FF6B00] text-center py-[2px] font-semibold border-white border-b-[1px]  border-r-[1px]">
                {Number(AQIInfo[0]?.so2_avg).toFixed(2)} ug/m³
              </h1>
              {/*  */}
              <h1 className="text-xl text-white text-center py-[2px] font-semibold border-white border-b-[1px] border-l-[1px] border-r-[1px]">
                PM2.5
              </h1>
              <h1 className="text-xl text-[#52FF00] text-center py-[2px] font-semibold border-white border-b-[1px]  border-r-[1px]">
                {Number(AQIInfo[0]?.pm25_avg || 0).toFixed(2)} ug/m³
              </h1>
              <h1 className="text-xl text-white text-center py-[2px] font-semibold border-white border-b-[1px]  border-r-[1px]">
                NO2
              </h1>
              <h1 className="text-xl text-[#FF6B00] text-center py-[2px] font-semibold border-white border-b-[1px]  border-r-[1px]">
                {Number(AQIInfo[0]?.no2_avg || 0).toFixed(2)} ug/m³
              </h1>
            </div>
          </div>
        </div>
        {/* diviver */}
        <div className="w-full h-[0.5px] bg-[#D9D9D9] my-4" />

        {/* disclamer */}
        <div className="overflow-x-hidden">
          <div className=" animate-marquee whitespace-nowrap flex gap-2 items-center justify-center">
            <span className=" text-white text-xl font-semibold flex">
              HIGHER NOISE LEVELS CAN HAVE HARMFUL EFFECT ON HEALTH{" "}
            </span>
            <img src={StarIcon} className="w-5 h-5" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appx;
