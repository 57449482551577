import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import Page1 from "./Pages/Page1";
import Page3 from "./Pages/Page3";
import Appx from "./Pages/Appx";
import moment from "moment";

export default function App() {
  const [AQIInfo, setAQIInfo] = useState<any>([]);
  const [NoiseInfo, setNoiseInfo] = useState<any>({ laeqt: null });

  const [AQIColor, setAPIColor] = useState({
    color: "#FFF500",
    type: "Moderate",
  });

  const getColorCodeHandler = ({ aqi }: { aqi: number }) => {
    console.log(aqi);
    if (aqi && aqi < 50) {
      setAPIColor({ color: "#00af50", type: "Good" });
    }
    if (aqi >= 50 && aqi <= 100) {
      setAPIColor({ color: "#92d14f", type: "Satisfactory" });
    }
    if (aqi >= 101 && aqi <= 200) {
      setAPIColor({ color: "#fffd03", type: "Moderate" });
    }
    if (aqi >= 201 && aqi <= 300) {
      setAPIColor({ color: "#fd9a00", type: "Poor" });
    }
    if (aqi >= 401) {
      setAPIColor({ color: "#bd0100", type: "Severe" });
    }
  };

  const GetInformationHandler = async ({ url }: { url: string }) => {
    await fetch(
      ` http://monster1.distronix.in:1100/v1.0/sens/real/get_24h_avg_p10?date_hour=${moment().format(
        "YYYY-MM-DD"
      )} 13&dev_id=${
        //@ts-ignore
        url.match(/=(.*?)&/)[1]
      }`
    )
      .then((response) => response.json())
      .then((result) => {
        if (result?.data) setAQIInfo(result?.data);
        getColorCodeHandler({ aqi: result?.data[0]?.aqi });
        console.log("AQI data API:", result?.data);
      })
      .catch((error) => console.log(error));

    await fetch(
      `http://monster1.distronix.in:2100/anms/sens/get_latest_data_p10?dev_id=${
        url.split("anms=")[1]
      }`
    )
      .then((response) => response.json())
      .then((result) => {
        if (result?.data) setNoiseInfo(result?.data);
        console.log("Noise data API:", result?.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    const fetchData = () => {
      GetInformationHandler({ url: window.location.href });
    };
    fetchData();

    const intervalId = setInterval(fetchData, 10 * 60 * 1000); // 10 minutes in milliseconds
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Swiper
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={false}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <Appx AQIInfo={AQIInfo} NoiseInfo={NoiseInfo} AQIColor={AQIColor} />
        </SwiperSlide>
        <SwiperSlide>
          <Page1 AQIInfo={AQIInfo} NoiseData={NoiseInfo} />
        </SwiperSlide>
        <SwiperSlide>
          <Page3 />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
