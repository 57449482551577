import React, { useState, useEffect } from "react";

import Logo from "../assets/Logo.png";
import WaterIcon from "../assets/WaterIcon.png";

import moment from "moment";
type Props = {};

const Page3 = (props: Props) => {
  return (
    <div className="flex justify-center items-center w-screen h-screen bg-[#181818]">
      <div className="h-[320px] w-[640px] bg-[#181818]  scale-y-[1.4]">
        <h1 className="text-[1.8rem] font-semibold text-white pt-9 text-center  mb-8">
          Fresh water is 0.002% on Earth
        </h1>
        <div className=" grid grid-cols-3 px-8">
          <div className="flex justify-center items-center">
            <img src={WaterIcon} className=" w-36 h-34" />
          </div>
          <div className="flex flex-col w-96 justify-center items-cente">
            <h1 className="text-white -ml-8 text-4xl font-semibold truncate">
              SAVE WATER
            </h1>
            <h1 className="text-white text-4xl font-semibold truncate">
              SAVE EARTH
            </h1>
          </div>
          <div className="flex justify-center items-center">
            <img src={Logo} className=" w-[9rem] h-[9rem] " />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page3;
